import { jsonFetch } from '../functions/http'
import { debounce } from '../functions/form'

export class CitySearchInput extends HTMLSelectElement {
  connectedCallback () {
    this.render()
  }

  render () {
    const innerHTML = `
            ${this.buildStyle()}
            <div class="city-search">
                <input id="searchInput" class="${
                  this.classList
                }" required type="search" autocomplete="off">
                <div id="autocomplete"></div>
            </div>
        `
    this.style.display = 'none'
    this.insertAdjacentHTML('afterend', innerHTML)
    this.input = this.parentElement.querySelector('#searchInput')
    this.autocompleteDiv = this.parentElement.querySelector('#autocomplete')
    this.autocompleteDiv.style.display = 'none'
    this.input.value = this.selected?.text || ''
    this.attachEventHandlers()
  }

  attachEventHandlers () {
    this.input.addEventListener(
      'input',
      debounce(this.autocomplete.bind(this), 300)
    )
  }

  autocomplete (e) {
    this.clearResults()
    this.value = e.target.value
    if (e.target.value !== '') {
      this.autocompleteDiv.style.display = 'block'
      jsonFetch(`/cities/search?q=${e.target.value}`).then(cities => {
        if (cities.length > 0) {
          cities.forEach(city => {
            const p = document.createElement('p')
            p.innerHTML = city.label
            p.id = city.value
            this.autocompleteDiv.appendChild(p)
            p.addEventListener('click', this.selectChoice.bind(this))
          })
        } else {
          this.autocompleteDiv.innerHTML = 'Pas de résultats'
        }
      })
    }
  }

  selectChoice (e) {
    const option = document.createElement('option')
    option.text = e.target.innerHTML
    option.value = e.target.id
    option.selected = true
    this.add(option)
    this.input.value = this.selected.text
    this.clearResults()
    this.dispatchEvent(new CustomEvent('change'))
  }

  clearResults () {
    this.autocompleteDiv.style.display = 'none'
    this.autocompleteDiv.innerHTML = ''
  }

  get selected () {
    return this.options[this.selectedIndex]
  }

  buildStyle () {
    return `
            <style>
                select[is=city-search] ~ .city-search {
                    display: inline-block;
                    position: relative;
                }
                select[is=city-search] ~ .city-search #searchInput {
                     width: 100%;
                }
                select[is=city-search] ~ .city-search #autocomplete {
                    display: inline-block;
                    z-index: 2000;
                    position: absolute;
                    width: 100%;
                    background: white;
                    border: 1px solid rgb(229, 231, 235);
                    border-bottom-right-radius: 0.75rem;
                    border-bottom-left-radius: 0.75rem;
                }
                select[is=city-search] ~ .city-search #autocomplete > p {
                    border: 1px solid rgb(229, 231, 235);
                    margin: 0;
                    padding: 0.25rem 0.5rem;
                    cursor: pointer;
                }
                select[is=city-search] ~ .city-search #autocomplete > p:last-child {
                    border: 0;
                }
                select[is=city-search] ~ .city-search #autocomplete > p:hover {
                   background-color: rgb(243, 244, 246);
                }
            </style>
        `
  }
}
