export function replaceChildNodes (oldEl, newEl) {
  const nodes = Array.from(newEl.children)
  oldEl.querySelectorAll('*').forEach(child => child.remove())
  nodes.forEach(node => {
    oldEl.appendChild(node)
  })
}

export function addEventListeners (elements, type, listener) {
  elements.forEach(e => e.addEventListener(type, listener))
}

export function parseTextToHtml (text) {
  const parser = new DOMParser()
  return parser.parseFromString(text, 'text/html')
}
